import { Controller } from "@hotwired/stimulus"

// Allows you to stack one drawer on top of another. It sets the base drawer to static,
// if not static already, then removes the static after closing the stacked drawer.
// Follows the Bootstrap pattern for the z-index, which is a 10 difference between the
// drawer and the backdrop.
export default class extends Controller {
  connect() {
    if (this.show) {
      const prevDrawer = $(".modal-drawer.in")

      $(this.element).on("show.bs.modal", () => {
        this.updatePrevDrawerBackdrop("static", prevDrawer)
      })

      $(this.element).on("hidden.bs.modal", () => {
        this.updatePrevDrawerBackdrop("", prevDrawer)
      })

      $(this.element).modal("show")
      this.updateIndexes(this.element, prevDrawer)
    }
  }

  dismiss() {
    $(this.element).modal("hide")
  }

  updatePrevDrawerBackdrop(val, prevDrawer) {
    const hasStatic = prevDrawer.attr("data-backdrop") == "static"
    const hasStaticData = prevDrawer.attr("data-has-static") == "true"

    if(hasStatic && !hasStaticData && val == "static"){
      prevDrawer.attr("data-has-static", true)
    }else if(hasStatic && hasStaticData){
      prevDrawer.removeAttr("data-has-static")
    }else{
      prevDrawer.attr("data-backdrop", val)
      prevDrawer.data("bs.modal").options.backdrop = val
    }
  }

  updateIndexes(element, prevDrawer){
    const prevIndex = prevDrawer.css("z-index")
    $(element).data("bs.modal").$backdrop.css("z-index", prevIndex + 10)
    $(element).css("z-index", prevIndex + 20)
  }

  get show() {
    const value = this.data.get("show")
    return value == "1" || value == "true"
  }
}