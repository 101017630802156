import TableController from "./table_controller.js"
import { initTableWithChildren, attachModalLinkEvents } from "huntressHelpers"
import { initFlowbite } from "flowbite"

export default class extends TableController {

  connect() {
    const dataUrl = this.data.get("url")
    const headers = JSON.parse(this.data.get("headers"))
    const queryData = JSON.parse(this.data.get("queryData"))

    const orderingEnabled = this.data.get("disableOrdering") === "false"
    const searchingEnabled = this.data.get("disableSearching") === "false"
    const searchPlaceholder = this.data.get("searchPlaceholder")
    const entriesDropDownEnabled = this.data.get("disableEntriesDropDown") === "false"
    const entriesToDisplay = parseInt(this.data.get("entriesToDisplay"))
    const lengthMenu = JSON.parse(this.data.get("lengthMenu"))
    const expandChildRowTarget = this.data.get("expandChildRowTarget")
    const domOverride = this.data.get("dom")
    const columnDefOverrides = JSON.parse(this.data.get("columnDefs"))
    const realtimeSettings = JSON.parse(this.data.get("realtimeSettings"))
    const bPaginate = this.data.get("disablePagination") === "false"
    const bInfo = this.data.get("disableBinfo") === "false"

    const classTargets = []
    const orderableTargets = []
    const searchableTargets = []
    const defaultOrderByPriority = {}
    const sortSequence = {}

    this.element[this.identifier] = this
    this.queryData = queryData

    headers.forEach((header, i) => {
      if(header.default_sort_priority !== undefined) {
        defaultOrderByPriority[header.default_sort_priority] = [i, header.sort_sequence[0]]
      }
      if(header.classes) {
        classTargets.push({
          targets: i,
          className: header.classes
        })
      }
      if(!header.orderable) {
        orderableTargets.push(i)
      }
      if(!header.searchable) {
        searchableTargets.push(i)
      }
      if(header.sort_sequence) {
        if(sortSequence[header.sort_sequence]) {
          sortSequence[header.sort_sequence].push(i)
        }
        else {
          sortSequence[header.sort_sequence] = [i]
        }
      }
    })

    const defaultOrder = Object.keys(defaultOrderByPriority).sort().map(
      key => defaultOrderByPriority[key]
    )

    const sortSequenceColumnDef = Object.keys(sortSequence).map(
      seq => ({ targets: sortSequence[seq], orderSequence: seq.split(",") })
    )

    const regularDom =
        "<'row'"+
          "<'search pull-left relative bottom-11 w-1/4'f " +
            "<'i icon-before icon-solid icon-search relative bottom-8 pl-2 w-2 text-gray-400'>" +
          ">" +
    "<'row '<''tr>>" +
      "<'row tw border-t-2 border-huntress-grey-50'>" +
        "<'row tw pagination-controls flex-row flex justify-between items-center mt-4'" +
          "<'flex-row flex justify-between items-center'" +
            "<'entries text-sm pr-3'i>" +
            "<'per-page per-page-selector text-sm border-l px-3'l>" +
          ">" +
          "<'datatable-pagination h-12 px-1 py-2 border-gray-300 justify-start items-center inline-flex'p>" +
        ">"
    const dom = domOverride || regularDom

    const controllerContext = this

    var columnDefs = [
      ...columnDefOverrides,
      ...sortSequenceColumnDef,

      { targets: orderableTargets, orderable: false },
      { targets: searchableTargets, searchable: false },
      ...classTargets,
      { targets: "_all", class: "py-4 pl-4 pr-3 text-sm font-medium sm:pl-0" },    // add class to td

    ]

    const options = {
      aaSorting: [],
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: entriesToDisplay,
      lengthChange: entriesDropDownEnabled,
      lengthMenu: lengthMenu,
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      responsive: true,
      ordering: orderingEnabled,
      searching: searchingEnabled,
      language: {
        searchPlaceholder: searchPlaceholder,
        search: ""
      },
      oLanguage: {
        oPaginate: {
          sPrevious: "← Prev",
          sNext: "Next →"
        }
      },
      order: defaultOrder,
      dom: dom,
      columnDefs: columnDefs,
      realtime: realtimeSettings,
      bPaginate: bPaginate,
      bInfo: bInfo,
      ajax: {
        url: dataUrl,
        data: function(data) {
          return $.extend( {}, data, controllerContext.queryData )
        }
      },
      drawCallback: function(settings) {
        controllerContext.attachResponsiveClasses()
        attachModalLinkEvents.bind(controllerContext.tableTarget)()

        let event = new CustomEvent("components.table.drawCallback",  { bubbles: true, detail: { dataTable: this, settings: settings } })
        controllerContext.tableTarget.dispatchEvent(event)

        const pageInfo = this.api().page.info()
        if(pageInfo.page > 0 && pageInfo.page >= pageInfo.pages) {
          setTimeout(() => {
            this.api().page(0).draw()
          }, 100)
        }
        // initilize flowbite so it can find elements after DataTable renders it
        initFlowbite()
      },
    }
    // pagination styling
    // parent div
    $.fn.dataTable.ext.classes.sPaging = "s-paging tw artemis-table-paginate h-12 px-1 py-2 border-gray-300 justify-start items-center inline-flex"
    // li elements for page buttons
    $.fn.dataTable.ext.classes.sPageButton = "tw p-2 rounded-lg gap-1 text-sm font-normal leading-none cursor-pointer text-blue-700 inline p-1 [&.active]:bg-blue-300 hover:bg-blue-100"

    // per_page select classes
    $.fn.dataTable.ext.classes.sLengthSelect = "sLengthSelect p-2 text-right text-sm pr-8 border-0 rounded-lg font-normal text-blue-500 focus:text-inherit cursor-pointer leading-none bg-inherit hover:bg-blue-100 hover:text-blue-500"

    // search filter classes
    $.fn.dataTable.ext.classes.sFilterInput = "sFilterInput tw indent-5 w-40 md:w-80 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-300 text-base sm:leading-6 py-1.5, font-normal font-['FontAwesome'] placeholder:text-gray-400"
    $.fn.dataTable.ext.classes.sSearch = ""

    // tr classes
    $.fn.dataTable.ext.classes.sStripeOdd = "hover:bg-disabled-grey "
    $.fn.dataTable.ext.classes.sStripeEven = "hover:bg-disabled-grey "

    // header sort classes
    $.fn.dataTable.ext.classes.sSortAsc = "icon-after icon-solid icon-sort-up "
    $.fn.dataTable.ext.classes.sSortDesc = "icon-after icon-solid icon-sort-down "
    $.fn.dataTable.ext.classes.sSortable = "icon-after icon-solid icon-sort "

    // these classes are not applied, so we shouldn't use them
    // $.fn.dataTable.ext.classes.sPageButtonDisabled = "sPageButtonDisabled "
    // $.fn.dataTable.ext.classes.sPageButtonActive = "sPageButtonActive bg-[#9bc3fd]"

    if (expandChildRowTarget) {
      initTableWithChildren($(this.tableTarget), options, expandChildRowTarget)
    }
    else {
      $(this.tableTarget).dataTable(options)
    }
  }
}