import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["btnUpdateAssociatedSignalNamesPreview", "associatedSignals", "threatForm"]

  removeSignalName(e) {
    let signal_name_id = e.target.dataset.signalNameId

    let threat_form = new FormData(this.threatFormTarget)
    let existing_signal_name_ids = threat_form.getAll("threat[signal_name_ids][]")
    let difference = existing_signal_name_ids.filter(x => ![signal_name_id].includes(x))

    $(this.associatedSignalsTarget).val(difference).trigger("change")

    this.#sendUpdateAssociatedSignalNamesPreviewRequest()
  }

  updateAssociatedSignalNamesPreview() {
    this.#sendUpdateAssociatedSignalNamesPreviewRequest()
  }

  #sendUpdateAssociatedSignalNamesPreviewRequest() {
    this.btnUpdateAssociatedSignalNamesPreviewTarget.click()
  }
}
