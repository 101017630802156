import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hiddenForm"]

  submitLoginLink() {
    if (this.hiddenFormTarget) {
      this.hiddenFormTarget.submit()
    }
  }
}
