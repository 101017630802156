import { Controller } from "@hotwired/stimulus"

/***
 * A modified version of the Select2 Plugin
 * it uses a custom select2 adapter to provide
 * typeahead-esque functionality.
 ***/
export default class extends Controller {
  connect() {
    this.defineAdapters()

    this.setupSelect2(this.element, this.options)
  }

  setupSelect2(el, options) {
    let self = this
    
    $(el).select2(options)

    $(el).on("select2:open", function () { self.displaySearch(el) })

    $(el).on("select2:close", function() {
      self.hideSearch(el)

      let event = new Event("select2:close:asNativeEvent", { bubbles: true })
      this.dispatchEvent(event)
    })

    $(el).on("select2:select", function() {
      let event = new Event("select2:select:asNativeEvent", { bubbles: true })
      this.dispatchEvent(event)
    })
  }

  hideSearch(el) {
    let selection_placeholder = $($(el).siblings(".select2-container")[0]).find(".select2-selection__placeholder")[0]
    selection_placeholder.style.display = "block"
    
    let search_field = $($(el).siblings(".select2-container")[0]).find(".select2-search__field")[0]
    search_field.style.display = "none"
  }

  displaySearch(el){
    let selection_placeholder = $($(el).siblings(".select2-container")[0]).find(".select2-selection__placeholder")[0]
    selection_placeholder.style.display = "none"

    let search_field = $($(el).siblings(".select2-container")[0]).find(".select2-search__field")[0]
    search_field.placeholder = this.options.placeholder
    search_field.style.display = "block"
    search_field.style.width = "100%"
  }
  
  defineAdapters() {
    // https://codingpipe.com/posts/extending-select2-with-adapters/
    $.fn.select2.amd.define(
      "TypeaheadAdapter",
      [
        "select2/utils",
        "select2/selection/multiple",
        "select2/selection/search",
        "select2/selection/placeholder",
        "select2/selection/eventRelay",
      ],
      function (
        Utils,
        MultipleSelection,
        Search,
        Placeholder,
        EventRelay,
      ) {

        // Decorates MultipleSelection with Placeholder
        let adapter = Utils.Decorate(MultipleSelection, Placeholder)

        // Decorates adapter with EventRelay - ensures events will continue to fire
        // e.g. selected, changed
        adapter = Utils.Decorate(adapter, EventRelay)

        // the search adapter calls this update
        adapter.prototype.update = function (data) {
          this.clear()
    
          let $rendered = this.$selection.find(".select2-selection__rendered")
          let placeholder = this.options.get("placeholder") || ""

          let formatted = `<span class="select2-selection__placeholder" style="padding-left: 12px;">${placeholder}</span>`

          // Pass selected and all items to display method
          // which calls templateSelection
          // formatted = this.display(data, $rendered)

          $rendered.empty().append(formatted)
        }
    
        adapter = Utils.Decorate(adapter, Search)
        
        return adapter
      },
    )
  }


  get parentModal() {
    return this.element.closest(".modal")
  }

  get options() {
    return {
      ...this.defaultOptions,
      ...this.providedOptions
    }
  }

  get providedOptions() {
    return this.element.dataset.options ? JSON.parse(this.element.dataset.options) : {}
  }

  get defaultOptions() {
    let options = {
      placeholder: "Select an option...",
      allowClear: true,
      theme: "bootstrap",
      width: "100%",
      selectionAdapter: $.fn.select2.amd.require("TypeaheadAdapter"),
    }

    if (this.parentModal) {
      options.dropdownParent = this.parentModal
    }

    return options
  }
}
