import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["socSupportChat"]

  open(evt) {
    zE.activate()
    evt.preventDefault()
  }

  get isSocSupportChatOffline() {
    return $zopim.livechat.departments.getDepartment("SOC Support")?.status !== "online"
  }

  updateSocSupportChatAvailability() {
    const isSocSupportChatOffline = this.isSocSupportChatOffline

    this.socSupportChatTarget.classList.toggle("disabled", isSocSupportChatOffline)
    $(this.socSupportChatTarget).tooltip(isSocSupportChatOffline ? "enable" : "disable")
  }

  liveChat(evt) {
    if (this.isSocSupportChatOffline) {
      this.updateSocSupportChatAvailability()
      evt.stopPropagation()
      return
    }

    zE("webWidget", "prefill", {
      name: {
        value: this.data.get("name"),
      },
      email: {
        value: this.data.get("email"),
      }
    })

    zE("webWidget", "updateSettings", {
      webWidget: {
        chat: {
          departments: {
            select: "SOC Support"
          },
          suppress: false
        },
        answerBot: { suppress: true },
        contactForm: { suppress: true },
        helpCenter: { suppress: true },
        talk: { suppress: true }
      }
    })

    zE("webWidget", "chat:addTags", ["request_SOC_support_chat"])

    zE("webWidget", "open")
  }
}
