import { Controller } from "@hotwired/stimulus"
import { parseNestedFormData, flashHighlightId } from "huntressHelpers"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = [
    "form",
    "messages",
    "submitForm",
    "usageLocationMessage",
  ]

  static outlets = [ "accounts--unwanted-access--rules--expiration-date" ]

  connect() {
    if(this.hasSubmitFormTarget){
      $(this.submitFormTarget).on("click", () => {
        this.submitForm()
      })
    }
  }

  parseFormData(){
    const formData = new FormData($("#attribute-rule-form").get(0))
    const formObject = parseNestedFormData(formData)
    return formObject
  }

  async submitForm(){
    if (!this.accountsUnwantedAccessRulesExpirationDateOutlet.hasValidValue()) {
      return this.displayMessage("error", 'Set an expiration date, or select "Never Expires".')
    }

    const formObject = this.parseFormData()
    if(this.flashTurboStreamId !== undefined) formObject["flash_turbo_stream_id"] = this.flashTurboStreamId

    const response = await fetch($("#attribute-rule-form").get(0).action, {
      method: formObject?._method?.toUpperCase() || "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formObject),
    })

    if(response.status != 200){
      try {
        const jsonResponse = await response.json()
        return this.displayMessage("error", jsonResponse.error)
      }
      catch (_) {
        // Reload if unable to parse error, there should be a flash message
        window.location.reload()
      }
    }

    const jsonResponse = await response.json()
    if(this.closeModalId !== null && this.flashTurboStreamId !== null){
      $("#"+this.closeModalId).modal("hide")
      Turbo.renderStreamMessage(jsonResponse.flash_turbo_stream)
    }else{
      window.location.hash = jsonResponse.redirect_anchor
      window.location.reload()
    }
  }

  determinationExpected(){
    this.accountsUnwantedAccessRulesExpirationDateOutlet.enableFields()

    if (this.hasUsageLocationMessageTarget) {
      this.usageLocationMessageTarget.classList.add("hidden")
    }
  }

  determinationUnauthorized(){
    this.accountsUnwantedAccessRulesExpirationDateOutlet.disableFields()

    if (this.hasUsageLocationMessageTarget) {
      this.usageLocationMessageTarget.classList.remove("hidden")
    }
  }

  displayMessage(level, message){
    const messagesEl = $(this.messagesTarget)
    const messageEl = messagesEl.find(".message")
    let color = ""
    messagesEl.find(".fa").each((_, e) => {
      $(e).hide()
    })
    switch (level) {
    case "success":
      color = "#18BC9C"
      messagesEl.find(".success-icon").show()
      break
    case "warning":
      color = "#3498DB"
      messagesEl.find(".warning-icon").show()
      break
    case "error":
      color = "#E74C3C"
      messagesEl.find(".error-icon").show()
      break
    }

    messageEl.text(message)
    messageEl.css("color", color)
    messagesEl.show()
    flashHighlightId(messagesEl, 500, color)
  }

  get closeModalId() {
    return this.data.get("closeModalId")
  }

  get flashTurboStreamId() {
    return this.data.get("flashTurboStreamId")
  }
}
