import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["close"]

  get modalContent() {
    return this.element.querySelector(".modal-content")
  }

  connect() {
    $(this.element).modal("show")

    $(window).on("keyup", this.keyup)
    $(document).on("click", this.click)
  }

  disconnect() {
    $(window).off("keyup", this.keyup)
    $(document).off("click", this.click)
  }

  close() {
    $(this.element).modal("hide")
  }

  closeTargetConnected(element) {
    this.close()
  }

  click = ev => {
    if (this.modalContent.contains(ev.target))
      return

    // If the target element is not in the DOM anymore, don't close the modal.
    // This can happen when select2 is used in modals.
    if (!document.documentElement.contains(ev.target))
      return

    if($(this.element).data("backdrop") == "static")
      return

    this.close()
  }

  keyup = ev => {
    if (ev.keyCode === 27) {
      this.close()
    }
  }

}
