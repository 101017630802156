import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  input(event) {
    event.target.parentNode.dataset.replicatedValue = event.target.value
  }

  submit(event) {
    event.target.form.submit()
  }

  focus(event) {
    event.target.setSelectionRange(0,event.target.value.length)
  }
}
