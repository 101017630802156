import { Controller } from "@hotwired/stimulus"
import { initTableWithChildren, attachModalLinkEvents } from "huntressHelpers"

// make sure to update artemis_table_controller.js when edit this one
export default class extends Controller {

  static targets = ["table"]

  connect() {
    const dataUrl = this.data.get("url")
    const headers = JSON.parse(this.data.get("headers"))
    const queryData = JSON.parse(this.data.get("queryData"))

    const orderingEnabled = this.data.get("disableOrdering") === "false"
    const searchingEnabled = this.data.get("disableSearching") === "false"
    const searchPlaceholder = this.data.get("searchPlaceholder")
    const entriesDropDownEnabled = this.data.get("disableEntriesDropDown") === "false"
    const entriesToDisplay = parseInt(this.data.get("entriesToDisplay"))
    const lengthMenu = JSON.parse(this.data.get("lengthMenu"))
    const expandChildRowTarget = this.data.get("expandChildRowTarget")
    const domOverride = this.data.get("dom")
    const columnDefOverrides = JSON.parse(this.data.get("columnDefs"))
    const realtimeSettings = JSON.parse(this.data.get("realtimeSettings"))
    const bPaginate = this.data.get("disablePagination") === "false"
    const bInfo = this.data.get("disableBinfo") === "false"

    const classTargets = []
    const orderableTargets = []
    const searchableTargets = []
    const defaultOrderByPriority = {}
    const sortSequence = {}

    this.element[this.identifier] = this
    this.queryData = queryData

    headers.forEach((header, i) => {
      if(header.default_sort_priority !== undefined) {
        defaultOrderByPriority[header.default_sort_priority] = [i, header.sort_sequence[0]]
      }
      if(header.classes) {
        classTargets.push({
          targets: i,
          className: header.classes
        })
      }
      if(!header.orderable) {
        orderableTargets.push(i)
      }
      if(!header.searchable) {
        searchableTargets.push(i)
      }
      if(header.sort_sequence) {
        if(sortSequence[header.sort_sequence]) {
          sortSequence[header.sort_sequence].push(i)
        }
        else {
          sortSequence[header.sort_sequence] = [i]
        }
      }
    })

    const defaultOrder = Object.keys(defaultOrderByPriority).sort().map(
      key => defaultOrderByPriority[key]
    )

    const sortSequenceColumnDef = Object.keys(sortSequence).map(
      seq => ({ targets: sortSequence[seq], orderSequence: seq.split(",") })
    )

    const regularDom =
        "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-4'i><'col-sm-8'p>>"
    const dom = domOverride || regularDom

    const controllerContext = this

    var columnDefs = [
      ...columnDefOverrides,
      ...sortSequenceColumnDef,
      { targets: orderableTargets, orderable: false },
      { targets: searchableTargets, searchable: false },
      ...classTargets,
    ]

    const options = {
      aaSorting: [],
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: entriesToDisplay,
      lengthChange: entriesDropDownEnabled,
      lengthMenu: lengthMenu,
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      responsive: true,
      ordering: orderingEnabled,
      searching: searchingEnabled,
      language: {
        searchPlaceholder: searchPlaceholder
      },
      order: defaultOrder,
      dom: dom,
      columnDefs: columnDefs,
      realtime: realtimeSettings,
      bPaginate: bPaginate,
      bInfo: bInfo,
      ajax: {
        url: dataUrl,
        data: function(data) {
          return $.extend( {}, data, controllerContext.queryData )
        }
      },
      drawCallback: function(settings) {
        controllerContext.attachResponsiveClasses()
        attachModalLinkEvents.bind(controllerContext.tableTarget)()

        let event = new CustomEvent("components.table.drawCallback",  { bubbles: true, detail: { dataTable: this, settings: settings } })
        controllerContext.tableTarget.dispatchEvent(event)

        const pageInfo = this.api().page.info()
        if(pageInfo.page > 0 && pageInfo.page >= pageInfo.pages) {
          setTimeout(() => {
            this.api().page(0).draw()
          }, 100)
        }
      },
    }
    if (expandChildRowTarget) {
      initTableWithChildren($(this.tableTarget), options, expandChildRowTarget)
    }
    else {
      $(this.tableTarget).dataTable(options)
    }
  }

  attachResponsiveClasses() {
    const headers = JSON.parse(this.data.get("headers"))
    const table = $(this.tableTarget)

    headers.forEach((header, idx) => {
      if(header.hideOnMobile) {
        table.find(`thead th:nth-child(${idx + 1})`).addClass("hidden-xs")
        table.find(`tbody tr td:nth-child(${idx + 1})`).addClass("hidden-xs")
      }
      if(header.hideOnDesktop) {
        table.find(`thead th:nth-child(${idx + 1})`).addClass("visible-xs")
        table.find(`tbody tr td:nth-child(${idx + 1})`).addClass("visible-xs")
      }
    })
  }

  reload(queryData = this.queryData) {
    this.queryData = queryData
    $(this.tableTarget).dataTable().api().ajax.reload()
  }

  resetPagination() {
    $(this.tableTarget).dataTable().api().page("first")
    $(this.tableTarget).dataTable().api().state.save()
  }
}
