import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "secondary"]

  connect() {
    this.selectTarget.addEventListener("change", (e) => {
      this.secondaryTargets.forEach(node => {
        if (e.currentTarget.value === node.dataset.match) {
          node.classList.remove("hidden")
        } else {
          node.classList.add("hidden")
        }
      })
    })
  }
}
