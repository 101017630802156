import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"
import { flashMessage } from "huntressHelpers"

export default class extends Controller {
  static targets = [
    "mappedOrganizationsTabs",
    "unmappedOrganizationsTabs",
    "mappedOrganizationsDatatable",
    "unmappedOrganizationsDatatable",
    "saveButton",
    "organizationM365Select",
    "organizationNameInput",
    "mappedTab",
    "unmappedTab",
  ]

  drawCount = 0
  initialMappings = {}
  cpvMappings = {}

  connect() {
    this.loadSelect2()
    if(!this.hasMappedOrganizationsDatatableTarget)
      return

    $(this.mappedOrganizationsDatatableTarget).on("init.dt", () =>{
      $(this.mappedOrganizationsTabsTarget).find("#loading").hide()
      $(this.mappedOrganizationsTabsTarget).find("#datatable").fadeIn()
    })

    $(this.unmappedOrganizationsDatatableTarget).on("init.dt", () =>{
      $(this.unmappedOrganizationsTabsTarget).find("#loading").hide()
      $(this.unmappedOrganizationsTabsTarget).find("#datatable").fadeIn()
    })

    const existingFilters = JSON.parse(this.mappedOrganizationsDatatableTarget.dataset.filters)
    const mappedFilters = { "mapped": true }
    existingFilters.forEach(filter => mappedFilters[filter] = true)

    const baseDataTableObj = {
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      columnDefs: [
        { targets: "_all", searchable: false },
        { targets: "no-sort", orderable: false },
        { className: "valign-middle", "targets": [ 0 ]}
      ],
      bPaginate: true,
      order: [[0, "asc"]],
      searching: true,
      responsive: true,
      serverSide: true,
      deferRender: true,
      language: {
        emptyTable: "Add a Tenant to see your mapped organizations",
      }
    }

    $(this.mappedOrganizationsDatatableTarget).dataTable(
      Object.assign(baseDataTableObj, {
        ajax: Routes.account_integrations_cpv_mapping_path({ format: "json", filters: mappedFilters }),
      })
    )

    $(this.unmappedOrganizationsDatatableTarget).dataTable(
      Object.assign(baseDataTableObj, {
        ajax: Routes.account_integrations_cpv_mapping_path({ format: "json", filters: { "unmapped": true } }),
      })
    )

    $(this.mappedOrganizationsDatatableTarget).on("draw.dt",(e) => {
      this.onDataTableLoad(this.mappedOrganizationsDatatableTarget)
      this.reloadMappings(this.mappedOrganizationsDatatableTarget)
      this.addTooltipToDisabledOptions({ mapped: true })
    })

    $(this.unmappedOrganizationsDatatableTarget).on("draw.dt", (e) => {
      this.onDataTableLoad(this.unmappedOrganizationsDatatableTarget)
      this.reloadMappings(this.unmappedOrganizationsDatatableTarget)
      this.addTooltipToDisabledOptions({ mapped: false })
    })

    $(this.mappedOrganizationsDatatableTarget).on("select2:select", ".cpv-mapping", this.handleMappedSelect.bind(this))
    $(this.mappedOrganizationsDatatableTarget).on("select2:unselect", ".cpv-mapping", this.handleMappedUnselect.bind(this))
    $(this.unmappedOrganizationsDatatableTarget).on("select2:select", ".cpv-mapping-unmapped", this.handleUnmappedSelect.bind(this))
    $(this.unmappedOrganizationsDatatableTarget).on("select2:unselecting", ".cpv-mapping-unmapped", this.handleUnmappedUnselect.bind(this))
  }

  toggleMatchingOptions({ mapped, value, disable }) {
    const klass = mapped ? ".cpv-mapping" : ".cpv-mapping-unmapped"
    $(klass).each((_idx, select2) => {
      $(select2.options).each((_jdx, option) => {
        if (option.value === value) {
          option.disabled = disable
          this.handleTooltip(option)
        }
      })
    })
  }

  addTooltipToDisabledOptions({ mapped }) {
    const klass = mapped ? ".cpv-mapping" : ".cpv-mapping-unmapped"
    $(klass).each((_idx, select2) => {
      $(select2.options).each((_jdx, option) => {
        this.handleTooltip(option)
      })
    })
  }

  handleTooltip(option) {
    $(option).attr("title", option.disabled ? "Tenant already mapped" : "")
  }

  handleUnmappedSelect(event) {
    this.toggleMatchingOptions({ mapped: false, value: event.target.value, disable: true })
    this.loadMapping(event.target)
    this.flashOrClearUnsavedMessage()
  }

  handleUnmappedUnselect(event) {
    this.toggleMatchingOptions({ mapped: false, value: event.params.args.data.id, disable: false })
    this.removeMapping(event.target)
    this.flashOrClearUnsavedMessage()
  }

  handleMappedSelect(event) {
    this.toggleMatchingOptions({ mapped: true, value: event.target.value, disable: true })
    this.loadMapping(event.target)
    this.flashOrClearUnsavedMessage()
  }

  handleMappedUnselect(event) {
    this.toggleMatchingOptions({ mapped: true, value: event.params.args.data.id, disable: false })
    this.removeMapping(event.target)
    this.flashOrClearUnsavedMessage()
  }

  flashOrClearUnsavedMessage() {
    if (JSON.stringify(this.cpvMappings) !== JSON.stringify(this.initialMappings)) {
      this.flashUnsavedChanges()
    } else {
      this.clearFlash()
    }
  }

  flashUnsavedChanges() {
    $(this.saveButtonTarget).attr("disabled", false)
    let flash = $("<span> You have unsaved changes </span>")
    $("<span class='text-button'>Save Changes</span>").on("click", () =>{
      this.saveMappings()
    }).appendTo(flash)
    flashMessage(flash[0], "warning", this.element, false)
  }

  clearFlash() {
    $(this.saveButtonTarget).attr("disabled", true)
    $(this.element).find(".alert").addClass("fadeOut")
    setTimeout(() => {
      $(this.element).find(".alert").remove()
    }, 500)
  }

  loadMapping({ id, value }) {
    this.cpvMappings[id.split("-").pop()] = value
  }

  removeMapping({ id }) {
    delete this.cpvMappings[id.split("-").pop()]
  }

  clearMappingForTenantId(tenantId) {
    for (let orgId in this.cpvMappings) {
      if (this.cpvMappings[orgId] === tenantId) {
        this.toggleMatchingOptions({ mapped: true, value: tenantId, disable: false })
        this.cpvMappings[orgId] = null
      }
    }
  }

  saveMappings() {
    $.ajax({
      url: Routes.update_mappings_account_integrations_cpv_mapping_path(),
      method: "POST",
      data: JSON.stringify({ mappings: this.cpvMappings }),
      dataType: "json",
      contentType: "application/json",
      complete: () => {
        window.location.reload()
      }
    })
  }

  reloadMappings(datatable) {
    $(datatable).find("tbody").find(".cpv-mapping").each((_, element) => {
      let mappedValue = this.cpvMappings[element.id.split("-").pop()]
      if (mappedValue){
        element.value = mappedValue
        $(element).trigger("change")
      }
    })
  }

  onDataTableLoad(datatable) {
    this.loadSelect2()
    $(datatable).find("tbody").find(".cpv-mapping").each((_, element) => {
      if (!this.cpvMappings[element.id.split("-").pop()])
        this.loadMapping(element)
    })

    if(this.drawCount < 2){
      this.initialMappings = JSON.parse(JSON.stringify(this.cpvMappings))
      this.drawCount++
    }
  }

  unmap(e) {
    const target = $(e.currentTarget)
    const orgId = target.data("organizationId")
    const tenantId = target.data("tenantId")
    const select = $(`#organization-${orgId}`)
    if (select.length) {
      this.clearMappingForTenantId(tenantId)
      select.val("")
      select.trigger("change")
      this.flashUnsavedChanges()
    } else {
      this.cpvMappings[orgId] = null
      this.saveMappings()
    }
  }

  loadSelect2(){
    $(".cpv-mapping").select2({
      theme: "bootstrap",
      allowClear: false,
      width: "100%",
      placeholder: "Unmapped",
      templateResult: this.select2Template,
      templateSelection: this.select2Template
    })

    $(".cpv-mapping-unmapped").select2({
      theme: "bootstrap",
      allowClear: true,
      width: "100%",
      placeholder: "Unmapped",
      templateResult: this.select2Template,
      templateSelection: this.select2Template
    })

    $(".cpv-mapping-create-org").select2({
      theme: "bootstrap",
      allowClear: true,
      width: "100%",
      placeholder: "Unmapped",
      templateResult: this.select2Template,
      templateSelection: this.select2Template
    })
  }

  select2Template(optionElement) {
    const text = optionElement.text
    if (text.endsWith("(directly integrated)") || text.endsWith("(MSP Tenant)")) {
      const textWithItalics = text.replace(/\((directly integrated|MSP Tenant)\)$/, '<span class="italic-light-text">($1)</span>')
      return $(`<span>${textWithItalics}</span>`)
    }

    return text
  }

  showUnmappedTenants() {
    this.mappedTabTarget.classList.remove("active")
    this.unmappedTabTarget.classList.add("active")
  }

  showMappedTenants() {
    this.unmappedTabTarget.classList.remove("active")
    this.mappedTabTarget.classList.add("active")
  }
}
